import React, { useState, useEffect } from 'react';
import { MapStateToProps, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Button, Avatar, Menu, MenuItem, ListItemButton, Box, Popper, Paper, ClickAwayListener, MenuList, Grow } from '@mui/material';
import Drawer from '@mui/material/Drawer';

//import Toolbar from '@mui/material/Toolbar';
import { RootState } from './../../redux/store';
import { connect, ConnectedProps } from 'react-redux';
import './Header.css';
import logo from './../../assets/images/logo_wescout_preto.jpg'
import borda from './../../assets/images/comum/photo_profile.png'
import { motion } from "framer-motion";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import List from '@material-ui/core/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Money from '@mui/icons-material/Money';

import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import Search from '@mui/icons-material/Search';

import AssignmentIcon from '@mui/icons-material/Assignment';
import { logout } from './../../redux/auth/actions'
import { fetchEmpresaById } from './../../redux/empresas/actions'
import { setImageProfile, getImagesByUser } from './../../redux/usuarios/actions'
import { toggleDrawerStart } from './../../redux/global/actions'
//import { MainListItems, SecondaryListItems, HeaderListItems } from './../Elements/listItems';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { serverConfig } from './../../api/apiConfig';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import { findProfileByUser } from './../../graphqlClient/graphql';


type PropsFromRedux = ConnectedProps<typeof connector>;

const Header: React.FC<PropsFromRedux> = ({ isLogged, user, imagesByUser, imageProfile, getImagesByUser, empresa, empresas, fetchEmpresaById, setImageProfile, logout, drawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const user = useSelector((state: RootState) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [role, setRole] = useState<any>('scoutme');
  // const [open, setOpen] = useState(false);
  const [empresaName, setEmpresaName] = useState('wescout');
  // 
  const menuItens = [{ name: 'Scout me', link: '/scoutme' }, { name: 'Job board', link: '/jobboard' }, { name: 'Scouting Pool', link: '/scoutingpoll' }, {
    name: 'Tools', link: [
      { name: 'AI Interview Simulator', link: '/aiinterviewsimulation' },
      { name: 'AI CV Analyse', link: '/airesumefeedback' },
      { name: 'AI VISA Guide', link: '/aivisaguide' },
    ]
  }, { name: 'Carrer Guide', link: '/carrerguide' }, { name: 'Get Premium', link: '/getpremium' }]
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const [open, setOpen] = React.useState(false);
  const [profileData, setProfileData] = React.useState<any>({});
  const [openCurriculum, setOpenCurriculum] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const getProfileByUserAsync = async (userId: any) => {

    try {
      const profile: any = await findProfileByUser(userId);
      //console.log('profile ==>>', profile)
      if (!profile) {
        //setOpenCurriculum(false)
      } else {
        //setOpenCurriculum(true)
        setProfileData(profile)
      }


    }
    catch (error) {
      //console.log("Erro no getUser >>>>>", error)
    }

  }
  useEffect(() => {
    if (user._id)
      getProfileByUserAsync(user._id);
  }, [user])

  const checkCurriculum = () => {
    if (!user.grade&&user.role==='scout') {
      setOpenCurriculum(true)
    } else {
      navigate('/scoutingpool');
    }
  }
  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  const handleCloseDialog = (event: Event | React.SyntheticEvent) => {

    setOpenCurriculum(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const toggleDrawer = () => {
    // setOpen(!open);
    dispatch(toggleDrawerStart())
  };



  useEffect(() => {
    if (empresa?.nome) {
      const name = empresa.nome
      setEmpresaName(name)
    }

  }, [empresa])

  useEffect(() => {
    
    if (user?.role) {
      setRole(user.role);
      //fetchEmpresaById(user.empresa)
      //getImagesByUser(user._id)
    }
    if(user.grade){
    
    }

  }, [user]);

  /*  useEffect(() => {
     setImageProfile('')
     console.log("imagesByUser", imagesByUser)
     const imagesList:any = [];
     let cont = 0
     imagesByUser.map((item:any, index:any)=>{
       cont = (cont < item.contador ? item.contador : cont)
       const slide = {
         key: index,
         title: 'Usuario',
         text: '',
         image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
         backgroundColor: 'green',
         
       }
       imagesList.push(slide)
     })
     console.log('imagesList ==>>> ', imagesList)
     console.log('cont ==>>> ', cont)
     
     if(imagesList.length){
       const image = imagesList[0].image
       setImageProfile(image)
     }
     //setSlides(imagesList)
    
   },[imagesByUser]); */

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (user?.confirmed)
      setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (e:any) => {
    handleProfileMenuClose()
    //setOpen(false)
    logout()
    navigate('/login');
    // Implemente a lógica para fazer logout do usuário
  };
  const handleProfile = () => {
    handleProfileMenuClose()
    navigate('/usuario');
    // Implemente a lógica para fazer logout do usuário
  };
  const handleAccount = () => {
    handleProfileMenuClose()
    navigate('/account');
    // Implemente a lógica para fazer logout do usuário
  };



  const goHome = () => {
    if (isLogged && user?.confirmed)
      toggleDrawer()
    //navigate('/')
  }
  const gotoPage = (link: any) => {

    navigate(link)
  }

  const getEmpresaNome = {

  }

  return (
    <>
      <Dialog
        open={openCurriculum}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Complete the requirements to join our Scouting Pool"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please, send your complete CV in PDF format to receive your feedback.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleCloseDialog}>Not now</Button>
          <Button variant='contained' onClick={(e: any) => {
            handleCloseDialog(e)
            navigate('/airesumefeedback')

          }} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <AppBar position="static">
        <Toolbar className='header'>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className='typography' onClick={goHome}>
            <img src={logo} className='logo' />
            <motion.div
              className="box"
              initial={{ opacity: 1 }}
              animate={{ opacity: .4, left: 260 }}
              transition={{ duration: 2 }} // Duração da animação em segundos
            />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {isLogged&&user.confirmed && menuItens.map((page) => {
              if (page.name === "Scout me" && role === "scouter") {
                return null;
              }
              return (
                <div style={{ minWidth: 'fit-content' }}>

                  <Button
                    variant="text"
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(e: any) => {
                      page.name == 'Tools' ? handleToggle(e) : (page.name == 'Scouting Pool' ? checkCurriculum() : gotoPage(page.link))
                    }}

                    key={page.name}


                    sx={{ my: 2, color: 'white', display: 'block' }}
                    className='verde'
                  >
                    {page.name}
                  </Button>
                  {page.name == 'Tools' ?
                    (
                      <Popper
                        open={open}
                        anchorEl={anchorEl1}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList

                                  autoFocusItem={open}
                                  id="composition-menu"
                                  aria-labelledby="composition-button"
                                  onKeyDown={handleListKeyDown}
                                >
                                  {/* <MenuItem onClick={(e: any) => {
                                    handleClose(e)
                                    gotoPage(page.link[0].link)

                                  }}>AI Interview Simulator</MenuItem> */}
                                  <MenuItem onClick={(e: any) => {
                                    handleClose(e)
                                    gotoPage(page.link[1].link)

                                  }}>AI CV Analyse</MenuItem>
                                  <MenuItem onClick={(e: any) => {
                                    handleClose(e)
                                    gotoPage(page.link[2].link)

                                  }}>AI VISA Guide</MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    )


                    : null}

                </div>
              )



            })}


          </Box>

          <div>

            {/* {role === 'admin' && (
            <Button color="inherit" component={Link} to="/teacher-menu">
              Menu do Administrador
            </Button>
          )} */}

            {
              isLogged ? (
                <Button color="inherit" onClick={handleProfileMenuOpen}>

                  <span className="user-info">
                    <span className="user-letter">
                      <img style={{ width: '56px', height: '56px', position: 'relative', top: '-.1', left: '53.1px', }} src={borda} alt="" />
                      <Avatar sx={{ width: 50, height: 50 }} alt={user.name} src={imageProfile || user.avatar} />
                    </span>
                    <span className="user-detail">
                      <span className="user-name">{user.name}</span>
                      <span className="user-role">{empresaName}</span>
                    </span>
                  </span>
                </Button>
              ) : null
            }


            <Menu

              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
            >

              <MenuItem style={{}} onClick={handleProfile}>
                <span className="user-info">
                  <span className="user-letter">
                    <img style={{ width: '70px', height: '70px', position: 'relative', top: '-.2', left: '67.1px', }} src={borda} alt="" />
                    <Avatar sx={{ width: 66, height: 66 }} alt={user.name} src={imageProfile || user.avatar} />
                  </span>
                  <span className="user-detail">
                    <span className="user-name">{user.name}</span>
                    <span className="user-name">{user.email}</span>
                    <span className="user-role">{'EDIT PROFILE'}</span>
                  </span>
                </span>

              </MenuItem>
              {/* <MenuItem style={{}} onClick={handleAccount}>Account</MenuItem> */}
              <MenuItem style={{}} onClick={handleProfile}>Profile</MenuItem>
              <MenuItem style={{}} onClick={handleLogout}>Log out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="persistent" open={drawer}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <React.Fragment>
            {/* <ListItemButton >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="dashboard" onClick={() => { navigate('/dashboard'); toggleDrawer(); }} />
            </ListItemButton> */}
            <ListItemButton>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              {
                user.role === 'scout' ?
                <ListItemText primary="home" onClick={() => { navigate('/scout'); toggleDrawer(); }} />
                :
                <ListItemText primary="home" onClick={() => { navigate('/scouter'); toggleDrawer(); }} />
              }
              
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <Money />
              </ListItemIcon>
              <ListItemText primary="scoutme" onClick={() => { navigate('/scoutme'); toggleDrawer(); }} />
            </ListItemButton>

            <ListItemButton >
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="scouting pool" onClick={() => { checkCurriculum(); toggleDrawer(); }} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <Search />
              </ListItemIcon>
              <ListItemText primary="job board" onClick={() => { navigate('/jobboard'); toggleDrawer(); }} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="profile" onClick={() => { navigate('/usuario'); toggleDrawer(); }} />
            </ListItemButton>
            
            
            {/* <ListItemButton >
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Unidades"  onClick={()=>{navigate('/categorias');toggleDrawer();}}/>
    </ListItemButton> */}

            {/* <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton> */}
          </React.Fragment>

          {role?.manager || role == 'admin' ? (
            <React.Fragment>
              <Divider sx={{ my: 1 }} />
              <ListSubheader component="div" inset>
                Scouter
              </ListSubheader>
              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Home" onClick={() => { navigate('/'); toggleDrawer(); }} />
              </ListItemButton>
              {/* <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Company" onClick={() => { navigate('/empresa'); toggleDrawer(); }} />
              </ListItemButton> */}




            </React.Fragment>
          ) : null}
          <React.Fragment>
            <Divider sx={{ my: 1 }} />

              <ListSubheader component="div" inset>
                Tools
              </ListSubheader>
              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="AI CV Analyse" onClick={() => { navigate('/airesumefeedback'); toggleDrawer(); }} />
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="AI VISA Guide" onClick={() => { navigate('/aivisaguide'); toggleDrawer(); }} />
              </ListItemButton>
          </React.Fragment>
          {role == 'admin' ? (

            <React.Fragment>
              <Divider sx={{ my: 1 }} />
              <ListSubheader component="div" inset>
                Administrador Master
              </ListSubheader>

              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Categories" onClick={() => { navigate('/categories'); toggleDrawer(); }} />
              </ListItemButton>

              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Companies" onClick={() => { navigate('/companies'); toggleDrawer(); }} />
              </ListItemButton>

              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Map" onClick={() => { navigate('/map'); toggleDrawer(); }} />
              </ListItemButton>

              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Users" onClick={() => { navigate('/usuarios'); toggleDrawer(); }} />
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Waitlist" onClick={() => { navigate('/waitlist'); toggleDrawer(); }} />
              </ListItemButton>
              {/* <ListItemButton>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Localizar Usuários" onClick={()=>{navigate('/location');toggleDrawer();}} />
        </ListItemButton> */}

            </React.Fragment>
          ) : null}


          <Divider sx={{ my: 1 }} />
          <ListItemButton >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" onClick={(e:any) => {
              handleLogout(e);
              toggleDrawer()
              //navigate('/login');
            }
            } />
          </ListItemButton>
        </List>
      </Drawer>
    </>

  );
};
const mapStateToProps = (state: RootState) => ({
  user: state.user.user, isLogged: state.auth.isLogged, // Ajuste aqui para a propriedade correta do estado do usuário
  drawer: state.global.drawer,
  empresa: state.empresas.empresa,
  empresas: state.empresas.empresas,
  imageProfile: state.usuarios.imageProfile,
  imagesByUser: state.usuarios.imagesByUser,
});



const mapDispatchToProps = {
  logout,
  fetchEmpresaById,
  setImageProfile,
  getImagesByUser

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Header);


import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/Avatar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { client, AUTH_USER, getUsers, whoami, UPDATE_USER, CREATE_USER, getCategories, getCompanies } from '../graphqlClient/graphql'
import SimpleAlert from './../components/Alert/Alert'
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Notifications from './../components/notifications/Notifications';
import { useNavigate } from 'react-router-dom'
import { Autocomplete } from '@mui/material';
import logo_centro from './../assets/images/login/logo_wescout.png'
import background from './../assets/images/sign/background_sign.png'
import texto from './../assets/images/sign/text_sign.png'
import globo from './../assets/images/sign/globo.png'
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://wescout.me">
        wwww.wescout.me
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const SignUpPage = () => {
  const params = useParams<{ type: string }>()
  const commonDomains = ['gmail', 'hotmail', 'outlook', 'yahoo', 'aol', 'live'];
  console.log('params ==> ', params)
  const [type, setType] = React.useState<any>('scout');
  const [corporateEmail, setCorporateEmail] = React.useState<any>(false);
  const [categories, setCategories] = React.useState<any>([]);
  const [companies, setCompanies] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});
  const [selectedCompany, setSelectedCompany] = React.useState<any>({});
  const [CreateUserMutation, { data }] = useMutation(CREATE_USER);

  console.log('signup', type)
  const navigate = useNavigate()

  const getCategoriesAsync = async () => {

    const result: any = await getCategories()
    console.log('resutl ===>', result)

    if (result?.length) {
      setCategories(result)
    }
  }

  const getCompaniesAsync = async () => {

    const result: any = await getCompanies()
    console.log('resutlgetCompanies ===>', result)

    if (result?.length) {
      setCompanies(result)
    }
  }

  React.useEffect(() => {
    ///getUsuarios();
    getCategoriesAsync();
    getCompaniesAsync();
  }, []);
  React.useEffect(() => {
    ///getUsuarios();
    //getCategoriesAsync();
    //getCompaniesAsync();
    if(params?.type!=''&&params?.type!='scout'){
      setType(params?.type)
    }
      
  }, [params]);

  const isCorporateEmail = (email:any) => { 
    console.log(email)
    if(!email.indexOf('@')||!email.indexOf('.')){
      setCorporateEmail(false)
      return false;
    }
    const domainPart = email.split('@')[1]
    const domain = domainPart.split('.')[0]; 
    console.log(domain)
    const res = commonDomains.includes(domain)
    console.log('res ==> ', res)
    setCorporateEmail(res)
    return res;
  }
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    //console.log('data ==>', data)
   
    console.log({
      email: data.get('email'),
      password: data.get('password'),
      confirmPassword: data.get('password'),
      name: data.get('firstName') + ' ' + data.get('lastName'),


    });

    try {
      const createUserInput = {

        email: data.get('email'),
        password: data.get('password'),
        confirmPassword: data.get('password'),
        name: data.get('firstName') + ' ' + data.get('lastName'),
        category: selectedCategory?._id || null,
        role: 'scout',
        //company: selectedCategory || null,

      }
      console.log('createUserInput', createUserInput)
      
      const result = await CreateUserMutation({
        variables: {
          createUserInput
        },
        onCompleted: ({ createUser }) => {

          console.log('user created== > ', createUser);
          const notData = {
            type: 'success',
            message: `User ${createUser?.name} Created Successfully!`,
            title: 'wescout',
            timeOut: 2500,
            callback: () => { },
            priority: true,
          }
          Notifications(notData)
          navigate('/login')
          //setUsuario(updateUser);
        }
      });
    } catch (error: any) {
      const notData = {
        type: 'error',
        message: error.message,
        title: 'wescout',
        timeOut: 2500,
        callback: () => { },
        priority: true,
      }
      Notifications(notData)
      console.log("Erro ao criar usuário", error.message)

    }



  };

  const handleButtonClick = (button:any) => { setType(button); };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div  style={{display: 'flex',
    width:'100%',
    maxHeight: '100vh',
    backgroundColor: 'transparent',
    justifyContent: 'space-between',
    overflow: 'hidden' }}>
        <CssBaseline />
        <div className={'leftContent'}>
          <div className={'imgBackgroundContainer'}>
            <img className={'imgBackground'} src={background} alt="" />
            <img className={'imgTexto'} src={texto} alt="" />
            <img className={'imgGlobo'} src={globo} alt="" />
          </div>
          {/*  */}
        </div>
        <div className={'rightContent'}>



          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            
            <Typography  style={{fontWeight:'bold'}} variant="h5" component="div">
              Create an account
            </Typography>
            <Typography  style={{fontWeight:'normal'}} variant="h6" component="div">
              Choose your perfil 
            </Typography>


            <Box sx={{
              minWidth: '90%',
              margin: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}>
            <Button variant='text'  className={type === 'scout' ? 'selected' : ''} onClick={() => handleButtonClick('scout')}>Scout me</Button>
            <Button variant='text' className={type === 'scouter' ? 'selected' : ''} onClick={() => handleButtonClick('scouter')}>Scout</Button>
            </Box>

            {type === 'scout' ?
              <Typography  style={{fontWeight:'bold', textAlign:'center'}}  component="div">
              Request a referral now
            </Typography>
             : 
             <Typography  style={{fontWeight:'bold', textAlign:'center'}}  component="div">
              Get in to your company´s referral program and get paid
            </Typography>
            }
            
            
            

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    onChange={(e:any)=>{
                      if(type==='scouter')  isCorporateEmail(e.target.value)
                    }}
                    helperText={type==='scout' ? 'Please enter your best e-mail':'Please enter your corporate e-mail'}
                    error={corporateEmail}
                    id="email"
                    label="E-mail Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="cpassword"
                    label="Confirm Password"
                    type="password"
                    id="cpassword"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ width: '100%' }} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', height: 50, alignContent: 'center', justifyContent: 'space-around', alignSelf: 'center', borderColor: 'green', borderWidth: 2 }}>
                    <Autocomplete
                      getOptionLabel={(cat: any) => cat.name}
                      //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                      onChange={(event, newValue) => {
                        console.log(newValue)
                        if (!newValue) {
                          setSelectedCategory({})
                          //changeCategory({})

                        }
                        setSelectedCategory(newValue)
                        //changeCategory(newValue)
                        //getInventoriesByCategory(newValue._id)
                      }}
                      disablePortal
                      id="combo-box-demo"
                      options={categories}
                      sx={{ width: '100%' }}
                      renderInput={(params: any) => <TextField {...params} label="Category" />}
                    />


                  </Box>
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField

                    fullWidth
                    name="linkedIn"
                    label="LinkedIn Profile"
                    type="link"
                    id="linkedIn"
                    autoComplete="LinkedIn"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="I want to receive news, promotional materials and updates by e-mail."
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                create account
              </Button>
              
        


            </Box>
            Already have an account?
                <Link href="/login" variant="h6">
                  sign in
                </Link>
          </Box>
        </div>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </div>
    </ThemeProvider>
  );
}

export default SignUpPage
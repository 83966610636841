import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CloudUpload, Email, Phone } from '@material-ui/icons';
import { ConnectedProps, connect } from 'react-redux';
import { updateUsuario, getImagesByUser, setImageProfile } from './../redux/usuarios/actions';
import { setUser } from './../redux/user/actions';
import { fetchEmpresas } from './../redux/empresas/actions';
import { deleteUsuario } from './../redux/usuarios/actions';
import { useNavigate } from 'react-router-dom';
import { serverConfig } from './../api/apiConfig';
import Avatar from '@mui/material/Avatar';
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, LinearProgress, MenuItem, Modal } from '@mui/material';
import { client, findProfileByUser, AUTH_USER, CREATE_PROFILE, filterProfile, getUsers, whoami, UPDATE_USER, UPDATE_PROFILE } from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import { UploadFile } from '@mui/icons-material';
// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Notifications from './../components/notifications/Notifications';
import planetPulse from 'planetpulse';

const workerUrl = new URL(
    'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js',
    import.meta.url,
).toString();
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        //alignItems: 'center',
        height: '100%',
    },
    content: {

        textAlign: 'center',
    },
    modalContent: {
        margin: "0 auto!Important",
        alignSelf: "center",
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignContent: 'center',
        flexWrap: 'nowrap',
        //alignItems: 'flex-start',
        // justifyContent: 'center',
        textAlign: 'center',
        border: '1px solid green',
        width: '80%!important',
        maxHeight: '100vh',
        overflowY: 'auto',
    },
    form_content: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        aligContent: 'center',
        justifyContent: 'flex-start',
        alignItems: 'center',

    },
    title: {
        marginBottom: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: green[500],
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        },
    }
}));
type PropsFromRedux = ConnectedProps<typeof connector>;
const AccountPage: React.FC<PropsFromRedux> = ({ setImageProfile, setUser }) => {
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    const allCountries = planetPulse.getAllCountries();
    //console.log('\nAll Countries:');
    //console.log(allCountries);
    const classes = useStyles();
    const screenWidth: any = getWindowDimensions().width
    const screenHeight: any = getWindowDimensions().height
    const navigate = useNavigate();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
    const [newUsuarioPassword, setNewUsuarioPassword] = React.useState<any>('');
    const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>('');
    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');

    const [loading, setLoading] = React.useState(false);

    const permissoesList = ['administrador', 'gerente', 'basico']
    const [contador, setContador] = React.useState(0);
    const [file, setFile] = React.useState<File>();
    const [image, setImage] = React.useState<any>('');
    const [open, setOpen] = React.useState<any>(false);
    const [update, setUpdate] = React.useState<any>(false);
    const [hasProfile, setHasProfile] = React.useState<any>(false);
    const [usuario, setUsuario] = React.useState<any>({});
    const [openPdf, setOpenPdf] = React.useState<any>(false);


    const [CreateProfileMutation, { data }] = useMutation(CREATE_PROFILE);
    const [UpdateProfileMutation, { data: updateProfileData }] = useMutation(UPDATE_PROFILE);
    const fields = [

        'Bio', //(Descrição como colocamos no LinkedIn (About me);
        'Professional title',
        'Years of experience',
        'Location',
        'Desired companies (3 max)',
        'Open to relocation',
        'Enable Scouting Pool',];
    //  ( Para permitir scouting pool onde referenciadores (scouters)  de qualquer empresa possam entrar em contato para indicá-lo, preencha todo o seu perfil, incluindo a adição de um currículo primeiro, dessa forma, o usuário só vai conseguir permitir o scouting pool depois de completar todo o cadastro, incluindo upload do CV (documento fundamental para essa “ferramenta” funcionar.

    // Neste momento informar o candidato da importância em praticar com a simulação das entrevistas por conta nota que ele vai obter durante a entrevista que será apresentada junto com a nota atribuída pela análise do CV sempre que o scouter colar um URL de vaga contendo um descritivo de uma vaga qd ele estiver fazendo sua busca pelos melhores candidatos.

    // Dessa forma, a “economia” do projeto se beneficia uma vez que ele vai precisar ter uma conta premium para poder praticar mais vezes e ter uma nota melhor para ser mostrada.

    // Campo para subir o CV



    /* useEffect(() => {
      console.log("imagesByUser useEffect", imagesByUser)
      const imagesList:any = [];
      let cont = 0
      imagesByUser.map((item:any, index:any)=>{
        cont = (cont < item.contador ? item.contador : cont)
        const slide = {
          key: index,
          title: 'Usuario',
          text: '',
          image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
          backgroundColor: 'green',
          
        }
        imagesList.push(slide)
      })
      console.log('imagesList ==>>> ', imagesList)
      console.log('cont ==>>> ', cont)
      setContador(cont)
      if(imagesList.length){
        const image = imagesList[0].image
        setImage(image)
        setImageProfile(image)
      }
      //setSlides(imagesList)
     
    },[imagesByUser]); */


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setImage(URL.createObjectURL(e.target.files[0]))
        }
    };



    const handleFileChangePdf = (event: any) => {
        const file = event.target.files[0];
        const fileSize = file.size / 1024 / 1024; // in MB
        const fileType = file.type;
        setLoading(true)
        if (fileType !== 'application/pdf') {
            alert('Por favor, selecione apenas arquivos PDF.');
            event.target.value = null; // limpa o valor do input
        } else if (fileSize > 10) {
            alert('O arquivo selecionado é muito grande. Por favor, selecione um arquivo de até 10MB.');
            event.target.value = null; // limpa o valor do input
        } else {
            // processa o arquivo...
            setFile(event.target.files[0]);
        }
        setLoading(false)
    };

    const getProfileByUserAsync = async (userId: any) => {
        setLoading(true)
        try {
            const profile: any = await findProfileByUser(userId);
            console.log('profile ==>>', profile)
            if (!profile) {
                setHasProfile(false)
            } else {
                setHasProfile(true)
                setEditUsuarioData(profile)
            }


        }
        catch (error) {
            console.log("Erro no getUser >>>>>", error)
        }
        setLoading(false)
    }
    const getUser = async () => {
        setLoading(true)
        try {
            const user: any = await whoami();
            console.log('user ==>>', user)
            setUsuario(user);
            getProfileByUserAsync(user._id)
        }
        catch (error) {
            console.log("Erro no getUser >>>>>", error)
        }
        setLoading(false)
    }
    useEffect(() => {

        getUser();

    }, [])
    useEffect(() => {
        let perm = 'basico'
        if (!usuario.curriculum) {
            setOpen(true)
        }
        if (usuario.permissoes?.admin) {
            perm = 'administrador'
        } else if (usuario.permissoes?.manager) {
            perm = 'gerente'
        }
        setNewUsuarioPermissoes(perm)
        //setEditUsuarioData(usuario)
        if (usuario?.image) {
            const imagePath = serverConfig.dev.url + 'images/' + usuario.image;
            setImage(imagePath)
            setImageProfile(imagePath)
            console.log('configurou o image profile')
        }
        if (usuario && usuario.name) setUser(usuario)
    }, [usuario])

    const makePermissoes = () => {
        if (newUsuarioPermissoes == 'administrador') {
            return { admin: true }
        } else if (newUsuarioPermissoes == 'basico') {
            return { basic: true }
        } else if (newUsuarioPermissoes == 'gerente') {
            return { manager: true }
        } else {
            return {}
        }
    }

    const createUser: any = async (createProfileInput: any) => {
        setLoading(true)
        let variables = { createProfileInput }
        console.log('createProfileInput', createProfileInput)

        try {
            const userToken = await CreateProfileMutation({
                variables,
                onCompleted: ({ createProfile }) => {

                    console.log('user result== > ', createProfile);
                    getProfileByUserAsync(usuario._id)
                }
            });
        } catch (error: any) {
            console.log("Erro ao atualizar usuário", error.message)

        }
        setLoading(false)

    }
    const updateUser: any = async (updateProfileInput: any) => {
        setLoading(true)
        let variables: {}
        variables = { updateProfileInput }

        try {
            const userToken = await UpdateProfileMutation({
                variables,
                onCompleted: ({ updateProfile }) => {

                    console.log('updateProfile result== > ', updateProfile);
                    getProfileByUserAsync(usuario._id)
                    const notData = {
                        type: 'success',
                        message: `Account updated successfully!`,
                        title: 'wescout',
                        timeOut: 2500,
                        callback: () => { },
                        priority: true,
                    }
                    //checkReferrals(user)
                    Notifications(notData)
                }
            });
        } catch (error: any) {
            console.log("Erro ao atualizar usuário", error)
            const notData = {
                type: 'error',
                message: error.message,
                title: 'wescout',
                timeOut: 2500,
                callback: () => { },
                priority: true,
            }
            Notifications(notData)

        }
        setLoading(false)

    }

    const handleUpdate = async () => {
        //setUpdate(true)
        console.log('editUsuarioData', editUsuarioData)
        const dataToSend = { ...editUsuarioData }
        //const permissoes = makePermissoes()
        //dataToSend.permissoes = permissoes
        if (file) {
            console.log(file)
            dataToSend.pdf = file;
            //dataToSend.contador = (contador+1);
        }
        // dataToSend.id = dataToSend._id
        if (dataToSend.__typename) delete dataToSend.__typename
        if (dataToSend.user) delete dataToSend.user
        if (dataToSend.createdAt) delete dataToSend.createdAt

        if (typeof dataToSend.pdf == 'string') delete dataToSend.pdf
        console.log(hasProfile)
        if (hasProfile) {
            updateUser(dataToSend)
        } else {
            dataToSend.user = usuario._id
            await createUser(dataToSend)
        }
        //console.log('dataToSend', dataToSend)



    };

    const validateEmail = (email: any) => {
        return true
        // Implemente a lógica de validação do e-mail aqui
    };

    const validatePassword = (password: string | any[]) => {
        return password.length >= 6;
    };

    const validateConfirmPassword = (password: any, confirmPassword: any) => {
        return password === confirmPassword;
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container className={classes.container}>
            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Complete the requirements to join our Scouting Pool"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please, send your complete CV in PDF format to receive your feedback.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog> */}
            <div className={'content'}>
                {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}

                {loading ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}
                {/* Adicione os componentes e funcionalidades específicas da página do usuario */}
                <div className={classes.form_content}>
                    <div className='accountData'>
                        <Typography variant="h5" style={{ fontWeight: 'bold', margin: '10px' }} >
                            Professional Info
                        </Typography>
                    </div>

                    {/* <div>
                       
                        <div className="file-upload">

                            <UploadFile />
                            {editUsuarioData.pdf ?
                                <div >
                                    <div>
                                        <span className='subtitle'>{editUsuarioData.pdf}</span> </div>
                                    <Button variant='outlined' onClick={() => setOpenPdf(true)}>Curriculum(PDF Viewer)</Button>
                                </div>

                                : null}
                            {file ? <div style={{ color: 'white' }}>file to upload: <span className='subtitle'>{file.name}</span> . </div> : null}
                       
                            <p>Maximum length 10mb</p>
                           
                            {
                                !file?.name ? <label htmlFor="contained-button-file">
                                    <input
                                        accept="application/pdf"
                                        style={{ display: 'none' }}
                                        id="contained-button-file"
                                        type="file"
                                        onChange={handleFileChangePdf}
                                    />
                                    <Button variant="contained" component="span">
                                        Choose a pdf file
                                    </Button>
                                </label> : (null
                                   
                                )
                            }

                        </div>


                    </div> */}
                    
                    <TextField
                        variant="outlined"
                        hiddenLabel
                        style={{ marginBottom: 5 }}
                        label="professional title"
                        value={editUsuarioData.professionalTitle || ""}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, professionalTitle: e.target.value })}
                    />
                    <TextField
                        style={{ marginBottom: 5 }}
                        variant="outlined"
                        hiddenLabel
                        label="bio"
                        multiline
                        maxRows={4}
                        value={editUsuarioData.bio || ""}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, bio: e.target.value })}
                    />
                    <TextField
                        variant="outlined"
                        hiddenLabel
                        style={{ marginBottom: 5 }}
                        label="years of experience do you have for the job you are looking for"
                        value={editUsuarioData.yearsOfExperience || ""}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, yearsOfExperience: e.target.value })}
                    />
                    <TextField
                        variant="outlined"
                        hiddenLabel
                        multiline
                        style={{ marginBottom: 5 }}
                        label="education background"
                        value={editUsuarioData.educationBackground || ""}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, educationBackground: e.target.value })}
                    />
                    <TextField
                        variant="outlined"
                        hiddenLabel
                        multiline
                        style={{ marginBottom: 5 }}
                        label="short description about your experience"
                        value={editUsuarioData.description || ""}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, description: e.target.value })}
                    />
                    {/* <TextField
                        variant="outlined"
                        hiddenLabel
                        style={{ marginBottom: 5 }}
                        label="location"
                        value={editUsuarioData.location || ""}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, location: e.target.value })}
                    /> */}
                    <Autocomplete
                        getOptionLabel={(usu: any) => usu.name}
                        value={{ name: editUsuarioData.location }}
                        //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                        onChange={(event, newValue) => {
                            console.log('selectedLocation', newValue)

                            if (newValue?.name) {
                               /*  const countryInfo = planetPulse.getCountryByName(newValue?.name);
                                console.log(countryInfo); */
                                setEditUsuarioData({ ...editUsuarioData, location: newValue.name, flag : newValue.flag })
                                //setUserCategory(newValue)
                                //changeFilter()
                            } else {
                                setEditUsuarioData({ ...editUsuarioData, location: '' , flag:''})
                                //setUserCategory({})
                                //changeFilter()
                            }


                        }}
                        disablePortal
                        id="combo-box-usuario"
                        options={allCountries}
                        sx={{ width: '90%', margin: '5px 0' }}
                        renderInput={(params: any) => <TextField {...params} label="Your location" />}
                    />

                    {/* <TextField
                        style={{ marginBottom: 5 }}
                        label="E-mail"
                        value={editUsuarioData.email}
                        onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                        error={!validateEmail(editUsuarioData.email)}
                        helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'}
                    /> */}

                    {/* {passwordOpen ? (
                        <>
                            <TextField
                                style={{ margin: 5 }}
                                label="Senha"
                                value={newUsuarioPassword}
                                onChange={(e) => {
                                    setEditUsuarioData({ ...editUsuarioData, password: e.target.value })
                                    setNewUsuarioPassword(e.target.value)
                                }}
                                type="password"
                                error={!validatePassword(newUsuarioPassword)}
                                helperText={!validatePassword(newUsuarioPassword) && 'A senha deve ter pelo menos 6 caracteres'}
                            />
                            <TextField
                                style={{ margin: 5 }}
                                label="Confirmar Senha"
                                value={newUsuarioConfirmPassword}
                                onChange={(e) => {
                                    setEditUsuarioData({ ...editUsuarioData, confirmPassword: e.target.value })
                                    setNewUsuarioConfirmPassword(e.target.value)
                                }}
                                type="password"
                                error={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword)}
                                helperText={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword) && 'As senhas não correspondem'}
                            />
                        </>

                    ) : (<Button className={classes.button} onClick={() => setPasswordOpen(true)}>change password</Button>)

                    } */}




                    {/* <FormControl fullWidth>
              <InputLabel htmlFor="permissao-select">Selecione o tipo de usuário</InputLabel>
              <Select
                value={newUsuarioPermissoes}
                onChange={(e:any) => setNewUsuarioPermissoes( e.target.value )}
                inputProps={{
                  name: 'permissao',
                  id: 'permissao-select',
                }}
              >
                {permissoesList.map((permissao: any) => (
                  <MenuItem key={permissao} value={permissao}>
                    {permissao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}


                    <Button className={classes.button} style={{ margin: 15, }} onClick={() => { handleUpdate() }}>update professional info</Button>





                    {/* <TextField
              label="Empresa"
              value={editUsuarioData.empresa}
              onChange={(e) => setNewUsuarioEmpresa(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editUsuarioData.empresa == ""}

              helperText={
                editUsuarioData.empresa=="" &&
                'Empresa inválida'
              }
            /> */}
                </div>
                {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
            </div>
            <Modal className={classes.modalContent} open={openPdf} onClose={() => setOpenPdf(false)}   >
                <div style={{ margin: 0, backgroundColor: 'white', width: screenWidth }} className={classes.modalContent}>

                    <Button className='backbutton' onClick={() => setOpenPdf(false)}>Close</Button>




                    <Worker workerUrl={workerUrl}>
                        <Viewer
                            fileUrl={serverConfig.dev.url + 'images/pdf/' + editUsuarioData.pdf}
                            plugins={[
                                // Register plugins
                                defaultLayoutPluginInstance,
                            ]}

                        />
                    </Worker>


                </div>
            </Modal>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = {
    setUser,
    setImageProfile
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AccountPage);



import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Collapse, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CardMedia from '@mui/material/CardMedia';
import { Link as LinkIcon, Close } from '@material-ui/icons';
import borda from './../assets/images/comum/photo_profile.png'
import CloudUpload from '@material-ui/icons/CloudUpload';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import QrCode from '@mui/icons-material/QrCode';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { connect, ConnectedProps } from 'react-redux';
import store from '../redux/store';
import { getUsuarios, deleteUsuario, createUsuario, updateUsuario } from '../redux/usuarios/actions';
import { getAllInventoryStart, consultProductStart, getInventoriesByCategory, getInventoriesByFilter } from '../redux/produtos/actions';

import { fetchEmpresas, } from '../redux/empresas/actions';
import { getImagesByReferencia, } from '../redux/referencias/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';

import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteOutlined from '@mui/icons-material/FavoriteOutlined';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';


import { InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, ExpandLess, ViewList, Link } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { Autocomplete, ButtonGroup, Card, CardActions, CardContent, CardHeader, Divider, FormControlLabel, FormGroup, LinearProgress, ListItemButton, ListSubheader, Stack, Switch } from '@mui/material';
import moment from 'moment'
import { VictoryBar, VictoryPie } from 'victory';
import { client, AUTH_USER, getUsers, whoami, UPDATE_USER, CREATE_USER, filterUser, getCategories, findProfileByUser, CREATE_RECOMMENDATION, filterChatgpt, AI_URL_FEEDBACK } from '../graphqlClient/graphql'

import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { serverConfig } from '../api/apiConfig';

// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useMutation } from '@apollo/client';
import Notifications from '../components/notifications/Notifications';
import planetPulse from 'planetpulse';
import { ClearIcon } from '@mui/x-date-pickers';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
    minWidth: '100%',
    maxWidth:'1200',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    width:'100%',
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between!important',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    margin: "0 auto!Important",
    alignSelf: "center",
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignContent: 'center',
    flexWrap: 'nowrap',
    //alignItems: 'flex-start',
    // justifyContent: 'center',
    textAlign: 'center',
    //border: '1px solid green',
    width: '80%!important',
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  listContainer: {
    //maxWidth:'80%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'center',
    alignItems: 'flex-start',

    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  irButton: {
    //width: 30,
    margin: 5
  }
}));

const chartConfig = {
  backgroundColor: '#26872a',
  backgroundGradientFrom: '#43a047',
  backgroundGradientTo: '#66bb6a',
  color: (opacity = 1) => `#ffffff`,
  style: {
    borderRadius: 16
  }
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const workerUrl = new URL(
  'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js',
  import.meta.url,
).toString();

type PropsFromRedux = ConnectedProps<typeof connector>;

const ScoutingPoolPage: React.FC<PropsFromRedux> = ({ usuario }) => {
  let newKeyword = ''
  const allCountries = planetPulse.getAllCountries();
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const screenWidth: any = getWindowDimensions().width
  const screenHeight: any = getWindowDimensions().height
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedUsuario, setSelectedUsuario] = React.useState('');
  const [messageToScout, setMessageToScout] = React.useState('');
  const [joblink, setJoblink] = React.useState('');

  const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
  const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>({});
  const [editUsuarioModalOpen, setEditUsuarioModalOpen] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [category, setCategory] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState<any>({});
  const [categoriesToShow, setCategoriesToShow] = React.useState([{ nome: 'Todas', _id: 0 }]);

  const [total, setTotal] = React.useState<any>(0);
  const [dataChart, setDataChart] = React.useState<any>([]);
  const [visible, setVisible] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  const [limit, setLimit] = React.useState(0);
  const permissoesList = ['administrador', 'gerente', 'basico']
  const [rows, setRows] = React.useState<any>([]);
  const [users, setUsers] = React.useState<any>([]);
  const [categories, setCategories] = React.useState<any>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedCategory, setSelectedCategory] = React.useState<any>({ key: 0, value: 'Filtro por unidade' });
  const [selectedLocation, setSelectedLocation] = React.useState<any>({});
  const [selectedKeywords, setSelectedKeywords] = React.useState<any>('');
  const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });
  const [selectedProduto, setSelectedProduto] = React.useState<any>({ key: 0, value: 'Filtro por produto' });
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [expanded, setExpanded] = React.useState('');
  const [openPdf, setOpenPdf] = React.useState(false);
  const [selectedScout, setSelectedScout] = React.useState<any>({});
  const [scoutInfo, setScoutInfo] = React.useState<any>(false);
  const [showJob, setShowJob] = React.useState<any>(false);
  const [openModalRecomendation, setOpenModalRecomendation] = React.useState<any>(false);
  const [link, setLink] = React.useState('');
  const [image, setImage] = React.useState<any>('');
  const [svg, setSvg] = React.useState<any>('');
  const [chatgpt, setChatgpt] = React.useState<any>([]);
  const [urlResult, setUrlResult] = React.useState<any>({});
  const [result, setResult] = React.useState<any>('');
  const [finalResult, setFinalResult] = React.useState<any>('');
  //
  const handleExpandClick = (id: string) => {
    const newValue = expanded == id ? '' : id
    setExpanded(newValue);
  };
  const apiRef = useGridApiRef();

  const [CreateRecommendationMutation, { data }] = useMutation(CREATE_RECOMMENDATION);
  const [AiUrlFeedback, { data:aiUrl }] = useMutation(AI_URL_FEEDBACK);
  const handleAddRecommendationPanel = async (scout: any) => {
    setSelectedScout(scout)
    setOpenModalRecomendation(true)

  }
  const handleAddRecommendation = async () => {
    setLoading(true);
    console.log('usuario', usuario)
    console.log('scout', selectedScout)

    const dataToSend = {
      scouter: usuario._id,
      scout: selectedScout._id,
      active: true,
      company: usuario.company._id,
      link: joblink,
      status: 'Under Review',
      details: 'Need your action',
      detailsScouter: 'Under Review by candidate',
      type: 'scoutingpool',
      message: messageToScout || '',

    }
    console.log('dataToSend', dataToSend)
    setOpenModalRecomendation(false)
    // dataToSend.id = dataToSend._id


    try {

      const createRecommendationInput = dataToSend
      console.log('createRecommendationInput', dataToSend)

      const result = await CreateRecommendationMutation({
        variables: {
          createRecommendationInput
        },
        onCompleted: ({ createRecommendation }) => {

          console.log(' created== > ', createRecommendation);
          const notData = {
            type: 'success',
            message: `Recommendation to ${createRecommendation?.company?.name} Created Successfully!`,
            title: 'wescout',
            timeOut: 2500,
            callback: () => { },
            priority: true,
          }

          Notifications(notData)
          setLoading(false);
          //setSelectedCompany({})
          //navigate('/scout')
          //setCompany(updateUser);
        }
      });
    } catch (error: any) {
      const notData = {
        type: 'error',
        message: error.message,
        title: 'wescout',
        timeOut: 2500,
        callback: () => { },
        priority: true,
      }
      Notifications(notData)
      console.log("Error at create credit", error)

    }

  };

  const getUsersAsync = async () => {
    setLoading(true)
    const profile: any = {}
    const objToSend: any = {
      //startDate,
      //endDate,
      //empresa:empresa
      role: 'scout',
      //usuario : selectedUser?._id || '',
    }
    if (selectedCategory) {
      objToSend.category = selectedCategory._id
    }
    if (selectedKeywords) {
      objToSend.keywords = selectedKeywords.toLowerCase()
    }
    if (newKeyword) {
      objToSend.keywords = newKeyword.toLowerCase()
      
    }
    if (selectedLocation.name) {
      profile.location = selectedLocation.name
      objToSend.profile = profile
    }
    //setIsLoadingCompaniesByFilter(true)
    console.log('objToSend', objToSend)

    const result = await filterUser(objToSend)
    console.log('filter users result ==>>', result)

    const users = result
    console.log('users == >> ', users)
    if (users.length) {
      setUsers(users)
    } else {
      setUsers([])
    }
    newKeyword=''
    setLoading(false)

  }
  const getCategoriesAsync = async () => {

    const result: any = await getCategories()
    console.log('categories ===>', result)

    if (result?.length) {
      setCategories(result)
    }
    return result;
  }




  useEffect(() => {
    //changeFilter()
    getUsersAsync()
  }, [selectedUser, selectedCategory, selectedProduto, startDate, endDate, selectedLocation]);
  useEffect(() => {
    //changeFilter()
    console.log('selectedKeywords', selectedKeywords)
    if(selectedKeywords==''){
      getUsersAsync();
    }
  }, [selectedKeywords]);


  useEffect(() => {
    getUsersAsync()
    getCategoriesAsync()
  }, []);
  let timer: any
  useEffect(() => {
    console.log('limit useEffect', limit)
    setProgress(limit)

  }, [limit]);
  useEffect(() => {
    console.log('urlResult useEffect', urlResult.jobtitle)
    

  }, [urlResult]);





  /* useEffect(() => {
      if(inventoriesList.length){
        
        apiRef.current.setColumnVisibility('_id', false);
      }
  }, [inventoriesList]); */



  const handleDeleteClick = (UsuarioId: string) => {
    console.log('UsuarioId', UsuarioId)
    setSelectedUsuario(UsuarioId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    console.log('selectedUsuario', selectedUsuario)
    deleteUsuario(selectedUsuario);
    setDeleteConfirmationOpen(false);
  };


  const makePermissoes = () => {
    if (newUsuarioPermissoes == 'administrador') {
      return { admin: true }
    } else if (newUsuarioPermissoes == 'basico') {
      return { basic: true }
    } else if (newUsuarioPermissoes == 'gerente') {
      return { manager: true }
    } else {
      return {}
    }
  }






  const handlerSelectedItem = (item: any) => {
    console.log(item, item)
    setSelectedItem(item)



  }

  const getCategoriaNome = (id: any) => {
    const nome = categories.filter((item: any) => id == item._id)
    return nome.length ? nome[0].nome : id

  }




  const clearData = (d: any) => {
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }




  interface Props {
    title: any
    subTitle: any
    itensList: []
    type: any
  }


  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"

          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }


  const handlerCloseModal = () => {
    setVisible(false)
    setLimit(0)
    setProgress(0)
    clearInterval(timer);
    setFinalResult('')
    setResult('')
    setSelectedScout({})
    setScoutInfo(false)
    setLoading(false)
  }

  const compare = (a: any, b: any) => {
    if (a.createdAt > b.createdAt)
      return -1
    else if (a.createdAt < b.createdAt)
      return 1
    else
      return 0

  }


  const changeCategoria = (cat: any) => {

    console.log('categoria id: ', cat)

    if (cat == 0) {
      getAllInventoryStart()
    } else {
      //get(cat)
    }

  }


  //init DataGrid dependencies

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row: any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row: any) => row._id !== id));
    }
  };

  const processRowUpdate: any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow', newRow)
    //setEditProdutoData(newRow)
    setRows(rows.map((row: any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type: 'Date',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => clearData(params.row.createdAt)
    },
    {
      field: 'categoria',
      headerName: 'Unidade',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => getCategoriaNome(params.row.categoria)
    },
    {
      field: 'usuario',
      headerName: 'Operador',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.usuario?.nome
    },

    {
      field: 'quantidade',
      headerName: 'Quantidade',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.conteudo.productsByCategory.length
    },
    {
      field: 'resultado',
      headerName: 'Resultado',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => (Math.round((params.row.conteudo.progress || 1) * 100)) + '%'
    },

    {
      field: 'identificados',
      headerName: 'Identificados',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => ((params.row.conteudo.tagsEquals.length || 1))
    },
    {
      field: 'tagsLost',
      headerName: 'Nâo encontrados',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => ((params.row.conteudo.tagsLost.length || 0))
    },
    {
      field: 'notfound',
      headerName: 'Externos',
      type: 'number',
      width: 80,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => ((params.row.conteudo.tagsNotFound.length || 0))
    },
    {
      field: 'lidos',
      headerName: 'Lidos',
      type: 'number',
      width: 80,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => ((params.row.conteudo.tagsFound.length || 1))
    },
    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        //const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const inventory = categories.filter((item: any) => item._id == id)[0]
        return [

          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom} title="Relatório" arrow>
              <IconButton className='iconDetail' color="primary" aria-label="Relatório" >
                <ViewHeadline />
              </IconButton>
            </Tooltip>}
            label="Relatório"
            className="textPrimary"
            onClick={() => handlerSelectedItem(inventory)}
            color="inherit"
          />,

        ];
      },
    },


  ];

  const hiddenFields = ['_id', 'id', 'ID'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    console.log('getTogglableColumns', columns)
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  interface AutocompleteOption {
    categoria: string;
  }

  const changeFilter = () => {
    setLoading(true)

    const objToSend = {
      startDate,
      endDate,

      //categoria:selectedCategory?._id || '',

    }
    console.log('objToSend', objToSend)
    //getInventoriesByFilter(objToSend)
    setLoading(false)

  }

  /* useEffect(() => {
    if(editUsuarioData.flag){
      fetch(editUsuarioData.flag)
        .then(res => res.text())
        .then(setSvg)
        .catch()
        .then(() => console.log('leu o svg'))
    }
}, [editUsuarioData.flag]); */
  useEffect(() => {
    let resultObj
    let final = ''
    if (result) {
      makeFinalResult(result)
    }
  }, [result]);
  function formatText(inputText: string): string {
    // Substitui quebras de linha por <br>
    const formattedText0 = inputText.replace(/Nota da Avaliação: (\d\/10)/, '<strong>Nota da Avaliação: $1</strong> <br>');
    const formattedText = formattedText0.replace('###', '');
    const formattedText1 = formattedText.replace(': -', ':');
    const formattedText2 = formattedText1.replace('. -', ':');

    // Aplica negrito para o texto entre ** **
    const boldText = formattedText2.replace(/\*\*(.*?)\*\*/g, '<br><strong>$1</strong>');

    return boldText;
  }

  const makeBullets = (val: any) => {
    let resp = ''
    if (val.length) {
        for (let z = 0; z < val.length; z++) {
            const valor = val[z]
            if(typeof valor != "string"){
                resp +=  makeBullets(valor)+"<br>"
            }else{
                resp += `* ${valor} <br>`
            }
            
        }
    } else {
        for (var i in val) {
            const valor = val[i]
            if(typeof valor != "string"){
                resp += "<h3>"+ i+"</h3><br>"+ makeBullets(valor)
            }else{
                resp += `* ${i}: ${valor} <br>`
            }
            
        }
    }
    return resp;
  }

  const makeFinalResult = (res: any) => {
    let resObj
    let final = ''
    if (res) {
      resObj = JSON.parse(res)
      const grade = Math.round(resObj.grade * 10)
      setLimit(grade)
      console.log('grade', grade)
      console.log('resObj', resObj)
      if (resObj) {
        for (var i in resObj) {
          const valor: any = resObj[i]
          final += '<h2>' + i + "</h2><br>"
          console.log('typeof valor', typeof valor)
          if (typeof valor == 'object') {
            final += makeBullets(valor)

          } else {
            final += valor
          }

        }
      }
      console.log('final', final)
      setFinalResult(final)
      return final
    }

  }

  const getChatgptAsync = async (userResume: any) => {

    const result: any = await filterChatgpt({ user: userResume._id, type: "airesumefeedback" })
    //console.log('resutl ===>', result)

    if (result?.length) {
      // if (result[0].response) setResult(result[0].response)
      setChatgpt(result)
      const index = result.length - 1
      const airesumefeedback = result[index]
      const res = airesumefeedback.response
      setResult(res)
    } else {
      setChatgpt([])
    }
  }

  const getProfileByUserAsync = async (userId: any) => {
    try {
      setLoading(true)
      console.log('userId', userId)
      const profile: any = await findProfileByUser(userId);
      console.log('profile ==>>', profile)
      setLoading(false)
      setEditUsuarioData(profile)

    }
    catch (error) {
      console.log("Erro no getUser >>>>>", error)
    }
  }

  const handleScoutInfo = async (user: any) => {
    console.log('user', user)
    getChatgptAsync(user)
    await getProfileByUserAsync(user._id)
    //setSelectedRecommendation(recom)
    setSelectedScout(user)
    if (user?.image) {
      const imagePath = serverConfig.dev.url + 'images/' + user.image;
      setImage(imagePath)
    }
    setScoutInfo(true)
    //setRequestPanel(true)
    //handleAddReferral(recom)
    //setRequestPanel(true)
  }

  


  const sendMessage = async () => {
    
    if (!joblink) {
        const notData = {
            type: 'error',
            message: 'Provide the job link',
            title: 'wescout',
            timeOut: 1000,
            callback: () => { },
            priority: true,
        }
        Notifications(notData)
        return;
    }
    const message = joblink
    const createChatgptInput: any = {
        user: usuario._id,
        message,
        type: 'aiurlfeedback',
    }
    
    delete createChatgptInput.__typename
    console.log('createChatgptInput', createChatgptInput)
    
    setLoading(true)
    try {
        const userToken = await AiUrlFeedback({
            variables: {
                createChatgptInput: createChatgptInput,

            },
            onCompleted: ({ aiurlresumefeedback }) => {

                console.log('aiurlresumefeedback result== > ', aiurlresumefeedback);
                /* const notData = {
                    type: 'success',
                    message: 'Your resume has been successfully reviewed',
                    title: 'wescout',
                    timeOut: 1500,
                    callback: () => { },
                    priority: true,
                }
                Notifications(notData) */
                let res = aiurlresumefeedback.response
                console.log('res',res)
                if(res == 'No response received from the assistant.'){
                  const notData = {
                    type: 'error',
                    message: 'No response received from the assistant',
                    title: 'wescout',
                    timeOut: 1000,
                    callback: () => { },
                    priority: true,
                  }
                  Notifications(notData)
                  setLoading(false)
                  return;
                }
                console.log("type", typeof res)
                if (typeof res == 'string') {
                  res = JSON.parse(res)
                }
                setUrlResult(res);
                setLoading(false);
                if(res.jobtitle){
                  console.log('res.jobtitle',res.jobtitle)
                  const keyword = res.jobtitle
                  setSelectedKeywords(keyword)
                  newKeyword = keyword
                  getUsersAsync()
                }
               
                //setChatgpt(aiurlresumefeedback)
                //getChatgptAsync(usuario)
                
                
                
            }
        });
    } catch (error: any) {
        const notData = {
            type: 'error',
            message: error.message,
            title: 'wescout',
            timeOut: 2500,
            callback: () => { },
            priority: true,
        }
        Notifications(notData)
        console.log("Erro ao atualizar usuário", error)
        setLoading(false)
    }
}

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <div className={classes.content}>
          {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
          <Typography variant="h4" className={'title'}>
            Scouting Pool
          </Typography>

          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}

        <Box sx={{ width: '100%', minHeight: 150, }} style={{ alignSelf: 'center', paddingTop: 10, paddingBottom: 10,   }}>

          <Typography component={'h2'} className='title'>
            Paste a link to the job listing (with job description)
          </Typography>
          <TextField
            style={{ maxWidth: "500px" }}
            label="Paste the link here"
            value={joblink}
            onChange={(e) => setJoblink(e.target.value)}
            
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon />
                </InputAdornment>
              ),
              endAdornment: selectedKeywords ? (
                <IconButton size="small" onClick={() => setJoblink('')}>
                  <ClearIcon />
                </IconButton>
              ) : undefined
            }}
          />
          <Button onClick={() => {
                                    if (joblink)
                                      sendMessage()
                                  }}>Search</Button>


            {
              urlResult?.jobtitle ? 
              <Box sx={{ width: '100%', height: 50, }} style={{ alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
              <Button onClick={() => {
                if (joblink)
                  setShowJob(true)
              }}>{`${urlResult?.jobtitle} at ${urlResult.company}` }</Button>
              </Box>
              : null
            }

          </Box>

          <Box sx={{ width: '100%', height: 110, }} style={{ alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
            <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
              <Box sx={{ width: '100%' }} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: 30, alignContent: 'center', justifyContent: 'space-around', alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>

                <Box sx={{ width: '100%', maxWidth: 350 }}>
                  <Autocomplete
                    getOptionLabel={(cat: any) => getCategoriaNome(cat.name)}
                    //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                    onChange={(event, newValue) => {
                      console.log('newValue ==> ', newValue)
                      setSelectedCategory(newValue)
                      if (!newValue) {

                        return
                      }

                    }}
                    disablePortal
                    id="combo-box-demo"
                    options={categories}
                    sx={{ width: 300 }}
                    renderInput={(params: any) => <TextField {...params} label="Category" />}
                  />
                  <Tooltip TransitionComponent={Zoom} title="Select the category of your interest" arrow>
                    <IconButton aria-label="settings">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>

                </Box>
                <Box sx={{ width: '100%', maxWidth: 350 }}>
                  <Autocomplete
                    getOptionLabel={(usu: any) => usu.name}
                    //value={{name : editUsuarioData.location}}
                    //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                    onChange={(event, newValue) => {
                      console.log('selectedLocation', newValue)

                      if (newValue?.name) {
                        setSelectedLocation(newValue)
                        //setUserCategory(newValue)
                        //changeFilter()
                      } else {
                        setSelectedLocation({})
                        //setUserCategory({})
                        //changeFilter()
                      }


                    }}
                    disablePortal
                    id="combo-box-usuario"
                    options={allCountries}
                    sx={{ width: '90%', margin: '5px 0' }}
                    renderInput={(params: any) => <TextField {...params} label="Location" />}
                  />
                  <Tooltip TransitionComponent={Zoom} title="Select the location of your interest" arrow>
                    <IconButton aria-label="settings">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>

                </Box>
                

              </Box>

              


            </Stack>


          </Box>
          <Box sx={{ width: '100%', maxWidth: '100%', marginBottom: 5 }}>
              
                  <TextField
                      style={{ marginBottom: 5, maxWidth:350 }}
                      variant="outlined"
                      hiddenLabel
                      label="type a word of interest to you"
                      
                      maxRows={1}
                      value={selectedKeywords}
                      onChange={(e) => {
                        setSelectedKeywords(e.target.value)
                      
                        }}
                        InputProps={{
                          endAdornment: selectedKeywords ? (
                            <IconButton size="small" onClick={() => setSelectedKeywords('')}>
                              <ClearIcon />
                            </IconButton>
                          ) : undefined
                        }}
                    />
                  <Tooltip TransitionComponent={Zoom} title="type a word of interest to you" arrow>
                    <IconButton aria-label="settings">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                  <Button onClick={() => {
                                    if (selectedKeywords)
                                      getUsersAsync()
                                  }}>Search</Button>
             
                </Box>
          {/*   {rows.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingInventory}
              getRowId={(row: { _id: any; }) => row._id}
              //checkboxSelection
          disableRowSelectionOnClick
          
          onClipboardCopy={(copiedString) => console.log(copiedString)}
          unstable_ignoreValueFormatterDuringExport
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
                
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              
              //editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'inventariosDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div style={{margin:15}}>
            not found
          </div>)} */}


          {users.length ? (
            <div className={classes.listContainer}>

              {users.map((user: any, index: any) => (
                <Card sx={{ maxWidth: 345 }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: '#0df228' }} aria-label="recipe">
                        {user.name.substring(0, 2)}
                      </Avatar>
                    }
                    /* action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    } */
                    title={user.name}
                    subheader={user.grade||''}
                  />
                  <CardMedia
                    style={{ backgroundColor: 'white' }}
                    component="img"
                    height="194"
                    image={user.image ? serverConfig.dev.url + 'images/' + user.image : "https://robohash.org/" + user.name}
                    alt="User image"
                  />
                  <CardContent style={{ backgroundColor: 'white' }}>
                    {
                      usuario.role === 'scouter' || usuario.role === 'admin' ?
                        <>
                          <Typography >
                            Invite this scout to be referred
                          </Typography>

                          <Button className='{classes.irButton}' onClick={() => {
                            handleAddRecommendationPanel(user)
                          }}>Invite</Button>
                        </> :
                        null
                    }

                    {/* <Typography variant="body2" color="primary">
                      <Button className='{classes.irButton}' onClick={() => {
                        handleScoutInfo(user)
                      }}>scout info</Button>
                    </Typography> */}
                  </CardContent>
                  <CardActions disableSpacing>

                    <IconButton aria-label="add to favorites">
                      <FavoriteOutlined />
                    </IconButton>
                    <Button variant='contained' className={classes.irButton} onClick={() => {

                      handleScoutInfo(user)
                    }}>Professional info</Button>

                    {/* <IconButton aria-label="share">
                      <ShareIcon />
                    </IconButton> */}
                    {
                      usuario.role === 'scouter' || usuario.role === 'admin' ?
                        <ExpandMore
                          expand={expanded == user._id ? true : false}
                          onClick={() => { handleExpandClick(user._id) }}
                          aria-expanded={expanded == user._id}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                        : null
                    }
                  </CardActions>
                  <Collapse in={expanded == user._id} timeout="auto" unmountOnExit>
                    <CardContent>


                      <Typography paragraph>
                        Invite this scout to be referred
                      </Typography>
                      <Typography paragraph>
                        <Button className='{classes.irButton}' onClick={() => {
                          handleAddRecommendationPanel(user)
                        }}>Invite</Button>
                      </Typography>

                    </CardContent>
                  </Collapse>
                </Card>
              ))}

            </div>

          ) : <Box>
            <Typography paragraph>
              No results were found based on your search.
            </Typography>
          </Box>}
          <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openModalRecomendation} onClose={() => setOpenModalRecomendation(false)}>
        <DialogTitle>Attach some infos to invite {selectedScout.name}</DialogTitle>
        <DialogContent>
          {/* <Typography variant="body1">Write your message</Typography> */}

          <Typography component={'h3'} >
            Paste a link to the job listing (with job description) by: {usuario?.company?.name || ''}
          </Typography>
          <TextField
            style={{ maxWidth: "500px" }}
            label="Paste the link here"
            value={joblink}
            onChange={(e) => setJoblink(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Link />
                </InputAdornment>
              ),
            }}
          /* error={!validateEmail(editUsuarioData.email)}
          helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'} */
          />


          <div style={{ fontSize: 18, margin: 15 }}>

            <TextField
              style={{ marginBottom: 5 }}
              variant="outlined"
              hiddenLabel
              label="Write your message"
              multiline
              maxRows={4}
              value={messageToScout || ""}
              onChange={(e) => setMessageToScout(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setOpenModalRecomendation(false)}>Cancel</Button>
          <Button variant='contained' onClick={() => handleAddRecommendation()} color="secondary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <Modal className={classes.modalContent} open={scoutInfo} onClose={() => handlerCloseModal()}   >

        <div style={{ margin: 0, backgroundColor: 'white', width: screenWidth }} className={classes.modalContent}>

          <Button className='backbutton' onClick={() => handlerCloseModal()}>Close</Button>

          <div className={classes.listContainer}>
            <div style={{ margin: 15, }}>
              <div className="file-upload">
                {/* <img src={uploadImg} alt="upload" /> */}
                <span className="user-info-column">
                  <span style={{ height: '90px' }}>
                    <div style={{ width: '85px', height: '85px', position: 'relative', }}>
                      <img width={85} src={borda} alt="" />
                    </div>

                    <Avatar sx={{ width: 81, height: 81, top: '-82px', left: "2px" }} alt={selectedScout.name} src={image || selectedScout.avatar} />
                  </span>
                  <span className="user-detail">
                    <Typography variant="h5" style={{ fontWeight: 'bold', marginTop: '5px' }}>{selectedScout.name}</Typography>
                    <Typography variant="h6" style={{ color: '#31D841' }}>{selectedScout.empresa?.name || 'Scout member'}</Typography>


                  </span>
                </span>
                {/* <Avatar sx={{ width: 108, height: 108 }} style={{margin:'0 auto'}} alt={usuario.name} src={image||usuario.avatar} /> */}



              </div>


              <div >


                <Stack borderRadius={15} spacing={2} direction="row" justifyContent={'center'} alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                  <div style={{ fontSize: 18, margin: 15 }}>Professional Title: {editUsuarioData.professionalTitle}</div>

                  <div style={{ fontSize: 18, margin: 15 }}>Years Of Experience: {editUsuarioData.yearsOfExperience}</div>


                </Stack>
                <Stack borderRadius={15} marginBottom={2} spacing={2} direction="row" justifyContent={'center'} alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                  {/* {editUsuarioData.flag&&svg?
                  <div 
                  //className={`svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
                  dangerouslySetInnerHTML={{ __html: svg }}
              />
                  :null} */}
                  <div>Origin Location: {selectedScout.country}</div>
                  <div>Nowdays Location: {editUsuarioData.location}</div>
                  

                </Stack>
                <Stack borderRadius={15} marginBottom={2} spacing={2} direction="row" justifyContent={'center'} alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                  <Typography >
                    English Level
                  </Typography>
                  <ButtonGroup variant="outlined" aria-label="English Level">
                    <Button className={selectedScout.englishLevel == 'B1' ? 'selected' : ''} >B1</Button>
                    <Button className={selectedScout.englishLevel == 'B2' ? 'selected' : ''} >B2</Button>
                    <Button className={selectedScout.englishLevel == 'C1' ? 'selected' : ''} >C1</Button>
                    <Button className={selectedScout.englishLevel == 'C2' ? 'selected' : ''} >C2</Button>
                  </ButtonGroup>
                </Stack>

                <Stack spacing={2}>
                  <FormGroup style={{ margin: '0 auto' }}>
                    <FormControlLabel control={<Switch checked={selectedScout.employed} defaultChecked={selectedScout.employed} />} label="Employed" />
                    {
                      selectedScout.employed && <FormControlLabel control={<Switch checked={selectedScout.opentorelocation} defaultChecked={selectedScout.opentorelocation} />} label="Open to relocation" />
                    }
                    <FormControlLabel control={<Switch checked={selectedScout.uePassport} defaultChecked={selectedScout.uePassport} />} label="UE passport" />

                  </FormGroup>
                </Stack>
                {limit ? (
                  <Stack spacing={2} margin={1.5} direction="column" justifyContent={'center'}>
                    <Typography>Resume feedback grade</Typography>
                    <div>
                      <CircularProgressWithLabel style={{ margin: 15 }} value={progress} />
                    </div>
                    

                  </Stack>) 
                 : null}
                <Stack spacing={2}>
                  {editUsuarioData.pdf ?
                    <div style={{ color: 'white' }}>
                      <div>file uploaled: <span className='subtitle'>{editUsuarioData.pdf}</span> . </div>
                      <Button variant='outlined' onClick={() => setOpenPdf(true)}>Curriculum(PDF Viewer)</Button>
                    </div>

                    : null}
                </Stack>

                



                <div style={{ fontSize: 18, margin: 15 }}>

                  <TextField
                    style={{ marginBottom: 5 }}
                    variant="outlined"
                    hiddenLabel
                    label="bio"
                    multiline
                    maxRows={4}
                    value={editUsuarioData.bio || ""}
                    onChange={(e) => setEditUsuarioData({ ...editUsuarioData, bio: e.target.value })}
                  />
                  
                </div>
                <Stack spacing={2} margin={1.5} direction="row" justifyContent={'center'}>
                <TextField
                    style={{ marginBottom: 5 }}
                    variant="outlined"
                    hiddenLabel
                    label="education background"
                    multiline
                    maxRows={4}
                    value={editUsuarioData.educationBackground || ""}
                    onChange={(e) => setEditUsuarioData({ ...editUsuarioData, educationBackground: e.target.value })}
                  />
                </Stack>
                <Stack spacing={2} margin={1.5} direction="row" justifyContent={'center'}>
                <TextField
                    style={{ marginBottom: 5 }}
                    variant="outlined"
                    hiddenLabel
                    label="short description about experience"
                    multiline
                    maxRows={4}
                    value={editUsuarioData.description || ""}
                    onChange={(e) => setEditUsuarioData({ ...editUsuarioData, description: e.target.value })}
                  />
                </Stack>
                <Divider />
                
                
                {

                  finalResult ? (
                    <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                      <div id="chatbox" className='chatbox'>
                        <div dangerouslySetInnerHTML={{ __html: finalResult }} style={{ width: '90%', maxWidth: '800px',fontSize:'16px!important', color:'#2F2F33!important', height: '300px', padding: '15px', fontFamily: 'Montserrat', textAlign: 'justify', margin: '0 auto', overflowY: 'scroll' }}>

                        </div>
            
                      </div>
                    </Stack>
                  ) : null
                }



                <Divider />
                {/* <CircularProgressWithLabel style={{ margin: 15 }} value={80} />
                <Divider /> */}
                {/* <div style={{ fontSize: 18, margin: 15 }}>
        <Link href={`${selectedRecommendation.link}`} target="_blank" underline="hover">
          {'Job link'}
        </Link>

      </div> */}
                {/* <div style={{ fontSize: 18, margin: 15 }}>Location: {selectedRecommendation.location}</div> */}
                {/* <div style={{}}>Itens lidos: {tagsFound.length} </div> */}
                
                {/* <div style={{ alignSelf: 'center', marginTop: 15 }}>
        <PieChart
          series={[
            {
              data: dataChart
            },
          ]}
          width={400}
          height={200}
        />
      </div> */}

                {/* <VictoryPie
            width={450}
            height={380}
            data={dataChart}
            labels={({ datum }) => `${datum.y}: ${datum.label}`}
            colorScale={["green", "red", "gold" ]}
            animate={{
              duration: 2000
            }}
            style={{
              data: {
                fillOpacity: 0.9, stroke: "#0af253", strokeWidth: 2, 
              },
              labels: {
                fontSize: 14,  marginLeft:-10
              }
            }}
          /> */}

              </div>
            </div>




          </div>

          {/* <div>
      //button to trigger printing of target component 
      <ReactToPrint
        trigger={() => <Button>Print this out!</Button>}
        content={() => componentRef}
      />

      
      <ComponentToPrint ref={(el) => (componentRef = el)} />
    </div> */}

        </div>
      </Modal>


      <Modal className={classes.modalContent} open={openPdf} onClose={() => setOpenPdf(false)}   >
        <div style={{ margin: 0, backgroundColor: 'white', width: screenWidth }} className={classes.modalContent}>

          <Button className='backbutton' onClick={() => setOpenPdf(false)}>Close</Button>




          <Worker workerUrl={workerUrl}>
            <Viewer
              fileUrl={serverConfig.dev.url + 'images/pdf/' + editUsuarioData.pdf}
              plugins={[
                // Register plugins
                defaultLayoutPluginInstance,
              ]}

            />
          </Worker>


        </div>
      </Modal>






      <Modal className={classes.modalContent} open={showJob} onClose={() => setShowJob(false)}>
      <div style={{ margin: '0 auto!important', backgroundColor: 'white', width: screenWidth }} className={classes.modalContent}>

        <Button className='backbutton' onClick={() => setShowJob(false)}>Close</Button>
          
           <Box sx={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
           <TextField
            type="text"
            value={urlResult.company}
            label="Company"
          //onChange={(e) => setEditUsuarioData({ ...urlResult, nome: e.target.value })}
          />
          
            <TextField
              type="text"
              value={urlResult.jobtitle}

            />
            <TextField
              type="text"
              value={urlResult.resume}
              multiline
              maxRows={4}
            />
            {
              urlResult.others ?
                <TextField
                  type="text"
                  value={urlResult.others}
                  multiline
                  maxRows={4}
                />
              : null
            }
            {
              urlResult.benefits ?
                <TextField
                  type="text"
                  value={urlResult.benefits}
                  multiline
                  maxRows={4}
                />
              : null
            }
            <TextField
              type="text"
              value={urlResult.location}

            />
            <TextField
              type="text"
              value={urlResult.type}

            />
          </Box>
        </div>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: state.user.user,
});

const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ScoutingPoolPage);


